<template>
  <v-app>
    <div class="fill-height light-blue lighten-4">
      <v-container class="align-center fill-height">
        <v-col grow>
          <v-alert border="left" colored-border color="amber" icon="mdi-comment-alert-outline" elevation="2" prominent>
            <p class="text-center pt-4 font-weight-bold" style="font-size: 36px">
              404 | Not Found
            </p>
          </v-alert>
          <v-alert border="left" colored-border color="light-blue" icon="mdi-information-variant" elevation="2" prominent>
            <p class="text-center pt-4 font-weight-bold" style="font-size: 20px">
              Halaman yang anda tuju tidak ditemukan. Hati - hati penipuan!
            </p>
          </v-alert>
        </v-col>
      </v-container>
    </div>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    Footer,
  },
};
</script>